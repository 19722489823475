<!-- INFO: support all v-chakra binding property-->

<template>
  <c-flex
    w="100%"
    flex-direction="row"
    align-items="start"
    justify-content="center"
    v-bind="$attrs"
  >
    <c-box
      v-for="(step, index) in steps"
      :key="step.label"
      ref="stepperRef"
      d="flex"
      flex-direction="row"
      align-items="center"
      justify-content="center"
      :width="['100px', '130px']"
    >
      <c-flex
        flex-direction="column"
        justify-content="center"
        align-items="center"
        :font-weight="['400', '500']"
        :font-size="['12px', '16px']"
        text-align="center"
      >
        <c-badge
          d="flex"
          align-items="center"
          justify-content="center"
          :font-size="['14px', '18px']"
          :bg="colorStep(step, index)"
          color="white"
          border-radius="50%"
          text-align="center"
          line-height="32px"
          :w="['32px', '50px']"
          :h="['32px', '50px']"
          position="relative"
          margin-bottom="4px"
        >
          {{ index + 1 }}
          <c-box
            v-if="!isFirstStep(index)"
            :height="['12px', '16px']"
            :width="['12px', '16px']"
            position="absolute"
            bottom="2px"
            :left="arrowPositionEl"
          >
            <inline-svg
              :src="require('@/assets/icons/icon-arrow-right-2.svg')"
              height="100%"
              width="100%"
              :fill="colorStep(step, index)"
            />
          </c-box>
        </c-badge>
        <c-text :color="colorStep(step, index)">
          {{ step.label }}
        </c-text>
      </c-flex>
    </c-box>
  </c-flex>
</template>
<script>

export default {
  name: 'StepperComp',
  inheritAttrs: false,
  props: {
    steps: {
      type: Array,
      default: () => [
        {
          label: 'Pilih Program',
        },
        {
          label: 'Informasi Pribadi',
          isCurrentStep: true,
          isError: true,
        },
        {
          label: 'Periksa',
        },
        {
          label: 'Pembayaran',
        },
      ],
    },
  },
  data() {
    return {
      arrowPositionEl: '0px',
    }
  },
  computed: {
    indexCurrentStep() {
      return this.steps.findIndex((step) => step.isCurrentStep)
    },
  },
  mounted() {
    this.distanceEl()
    this.$nextTick(() => {
      window.addEventListener('resize', this.distanceEl)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.distanceEl)
  },
  methods: {
    isFirstStep(index) {
      return index === 0
    },
    isBelowCurrentStep(index) {
      return index > this.indexCurrentStep
    },
    colorStep(step, index) {
      if (this.isBelowCurrentStep(index)) {
        return '#C4C4C4'
      }

      if (step.isError) {
        return '#D32737'
      }
      return '#008C81'
    },
    distanceEl() {
      const el1 = this.$refs.stepperRef?.[0]?.$el?.children?.[0]?.getBoundingClientRect()?.right
      const el2 = this.$refs.stepperRef?.[1]?.$el?.children?.[0]?.getBoundingClientRect()?.right
      if (el1 === undefined || el2 === undefined) return '0px'
      this.arrowPositionEl = `${Math.round((el1 - el2) / 3)}px`
    },
  },
}
</script>
